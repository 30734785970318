@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
  url("fonts/SF-Pro-Display/SF-Pro-Display-Black.otf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
  url("fonts/SF-Pro-Display/SF-Pro-Display-Heavy.otf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
  url("fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
  url("fonts/SF-Pro-Display/SF-Pro-Display-Semibold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
  url("fonts/SF-Pro-Display/SF-Pro-Display-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
  url("fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
  url("fonts/SF-Pro-Display/SF-Pro-Display-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
  url("fonts/SF-Pro-Display/SF-Pro-Display-Thin.otf") format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
  url("fonts/SF-Pro-Display/SF-Pro-Display-Ultralight.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
  url("fonts/SF-Pro-Text/SFProText-Heavy.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
  url("fonts/SF-Pro-Text/SFProText-Heavy.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
  url("fonts/SF-Pro-Text/SFProText-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
  url("fonts/SF-Pro-Text/SFProText-Semibold.ttf") format("truetype");
  font-weight: 600;
}


@font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
  url("fonts/SF-Pro-Text/SFProText-Medium.ttf") format("truetype");
  font-weight: 500;
}


@font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
  url("fonts/SF-Pro-Text/SFProText-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
  url("fonts/SF-Pro-Text/SFProText-Light.ttf") format("truetype");
  font-weight: 300;
}
