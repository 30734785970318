@import './variables';

$spacer: 4px;

@for $i from 0 through 30 {
  .m-#{$i} {
    margin: $i * $spacer;
  }
  .mx-#{$i} {
    margin-left: $i * $spacer;
    margin-right: $i * $spacer;
  }
  .my-#{$i} {
    margin-top: $i * $spacer;
    margin-bottom: $i * $spacer;
  }
  .mt-#{$i} {
    margin-top: $i * $spacer;
  }
  .mr-#{$i} {
    margin-right: $i * $spacer;
  }
  .mb-#{$i} {
    margin-bottom: $i * $spacer;
  }
  .ml-#{$i} {
    margin-left: $i * $spacer;
  }

  .p-#{$i} {
    padding: $i * $spacer;
  }
  .px-#{$i} {
    padding-left: $i * $spacer;
    padding-right: $i * $spacer;
  }
  .py-#{$i} {
    padding-top: $i * $spacer;
    padding-bottom: $i * $spacer;
  }
  .pt-#{$i} {
    padding-top: $i * $spacer;
  }
  .pr-#{$i} {
    padding-right: $i * $spacer;
  }
  .pb-#{$i} {
    padding-bottom: $i * $spacer;
  }
  .pl-#{$i} {
    padding-left: $i * $spacer;
  }

  .gap-#{$i} {
    gap: $i * $spacer;
  }
  .gap-x-#{$i} {
    column-gap: $i * $spacer;
  }
  .gap-y-#{$i} {
    row-gap: $i * $spacer;
  }
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

// sizes width
$size_step: 10;
@for $i from 1 through 10 {
  .w-#{$i * $size_step} {
    width: 1% * $i * $size_step;
  }
}

// sizes height
@for $i from 1 through 10 {
  .h-#{$i * $size_step} {
    height: 1% * $i * $size_step;
  }
}

// colors
@each $name, $color in $colors {
  .color-#{$name} {
    color: $color !important;
  }
}

// background-color
@each $name, $color in $colors {
  .bgc-#{$name} {
    background-color: $color !important;
  }
}

// opacity
@for $i from 0 through 10 {
  .opacity-#{$i} {
    opacity: $i / 10;
  }
}
