@import './helpers';
@import './typography';
@import './generated';
@import './variables';
@import './libs/tippy/styles';
@import './links.scss';
@import './shadows.scss';
@import './borders.scss';
@import './grid.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}

body,
#root {
  display: flex;
  min-height: 100vh;
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
  background: #eaeaec;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #2D2D47;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #F5D956;
  }
}

li {
  list-style: none;
}
