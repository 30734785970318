.text {
  color: #000000;
  font-feature-settings: 'zero' on;

  $font: "SF Pro Display";
  //$font-stack: -apple-system, BlinkMacSystemFont, sans-serif;
  $font-stack: $font;

  &.title-huge {
    font-family: $font-stack; //'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    line-height: 50px;
  }

  &.title-large {
    font-family: $font-stack; //'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 41px;
  }

  &.title-1 {
    font-family: $font-stack; //'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
  }

  &.title-2 {
    font-family: $font-stack; //'SF Compact Display', sans-serif;
    font-size: 20px;
    line-height: 26px;
    text-transform: uppercase;
  }

  &.title-3 {
    font-family: $font-stack; //'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
  }

  &.headline {
    font-family: $font-stack; //'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
  }

  &.body-text {
    font-family: $font-stack; //'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
  }

  &.callout {
    font-family: $font-stack; //'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
  }

  &.subhead {
    font-family: $font-stack; //'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
  }

  &.footnote {
    font-family: $font-stack; //'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
  }

  &.caption-1 {
    font-family: $font-stack; //'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }

  &.caption-2 {
    font-family: $font-stack; //'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
  }

  &.tip {
    font-family: $font-stack; //'SF Pro Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
  }

  &.bold {
    font-weight: bold;
  }

  &.center {
    text-align: center;
  }

  &.line-height-1 {
    line-height: 1;
  }

  &.fw-100 {
    font-family: $font;
    font-weight: 100;
  }

  &.fw-200 {
    font-family: $font;
    font-weight: 200;
  }

  &.fw-300 {
    font-family: $font;
    font-weight: 300;
  }

  &.fw-400 {
    font-family: $font;
    font-weight: 400;
  }

  &.fw-500 {
    font-family: $font;
    font-weight: 500;
  }

  &.fw-600 {
    font-family: $font;
    font-weight: 600;
  }

  &.fw-700 {
    font-family: $font;
    font-weight: 700;
  }

  &.fw-800 {
    font-family: $font;
    font-weight: 800;
  }

  &.fw-900 {
    font-family: $font;
    font-weight: 900;
  }
}
