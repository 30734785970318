$gutter: 24px;

.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, $gutter);
  padding-left: var(--bs-gutter-x, $gutter);
  margin-right: auto;
  margin-left: auto;
}

.row {
  --bs-gutter-x: 2 * $gutter;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  margin-top: var(--bs-gutter-y);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

@for $i from 1 through 12 {
  .col-#{$i} {
    width: #{(100% / 12) * $i};
  }
}

@for $i from 1 through 5 {
  .g-#{$i},
  .gx-#{$i} {
    --bs-gutter-x: #{$i * 12px};
  }
  .g-#{$i},
  .gy-#{$i} {
    --bs-gutter-y: #{$i * 12px};
  }
}
