@import './variables.scss';

.link {
  text-decoration: none;
  user-select: none;
  color: $SurfaceRomanSilver30;
  transition: 0.2s ease;

  &:hover,
  &.active {
    color: $AccentNapplesYellow;
  }

  &:active {
    color: $AccentJonquil;
  }

  &.disabled {
    pointer-events: none;
    color: $SurfacePlatinum20;
  }
}
