@import '../../variables.scss';

.tippy-content {
  padding: 8px 16px;
}

.tippy-box {
  background-color: $AccentMediumChampagne;
  color: $SurfaceRaisinBlack50;
  font-family: 'SF Pro Text', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
}

.tippy-arrow {
  color: $AccentMediumChampagne;
}
