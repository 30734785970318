@import './variables.scss';

.b-1 {
  border: 1px solid $SurfacePlatinum20;
}
.bb-1 {
  border-bottom: 1px solid $SurfacePlatinum20;
}
.bt-1 {
  border-top: 1px solid $SurfacePlatinum20;
}
.br-1 {
  border-right: 1px solid $SurfacePlatinum20;
}
.bl-1 {
  border-left: 1px solid $SurfacePlatinum20;
}

$steps: 10;
@for $i from 1 through $steps {
  .br-#{$i} {
    border-radius: 1px * $i;
  }
}
