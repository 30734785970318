.shadow-2 {
  box-shadow: 0px 0px 1px rgba(26, 26, 41, 0.08),
    0px 2px 4px rgba(26, 26, 41, 0.08) !important;
}
.shadow-4 {
  box-shadow: 0px 0px 1px rgba(26, 26, 41, 0.08),
    0px 4px 8px rgba(26, 26, 41, 0.08) !important;
}
.shadow-8 {
  box-shadow: 0px 0px 1px rgba(26, 26, 41, 0.08),
    0px 8px 16px rgba(26, 26, 41, 0.08) !important;
}
